import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fromUnixTime, subDays } from "date-fns";
import format from "date-fns/format";
import { GLOBAL_URL } from "src/utils";

export interface Event {
  id: string;
  post_id: string;
  isAdmin?: boolean;
  status: string;
  owner: boolean;
  from: string;
  to: string;
}

export interface Product {
  id: string;
  name: string;
  slug: string;
  events: Event[];
}

export interface Group {
  id: string;
  name: string;
  slug: string;
  events: Event[];
}

export const productsApi = createApi({
  tagTypes: ["Products"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${GLOBAL_URL}/api`,
  }),
  endpoints: (builder) => ({
    getProductsWithBooks: builder.query<
      Product[],
      { from: number; to: number }
    >({
      query: ({ from, to }) => ({
        url: `/?from=${format(
          subDays(fromUnixTime(from), 1),
          "yyyy-MM-dd"
        )}&to=${format(fromUnixTime(to), "yyyy-MM-dd")}`,
        method: "GET",
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const { useGetProductsWithBooksQuery } = productsApi;

//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import clone from "clone";
import PropTypes from "prop-types";
import { setAddToCartEvents } from "src/store/slices/date";
import { RootState } from "src/store/store";

class TableDragSelect extends React.Component {
  static propTypes = {
    value: (props) => {
      const error = new Error(
        "Invalid prop `value` supplied to `TableDragSelect`. Validation failed."
      );
      if (!Array.isArray(props.value)) {
        return error;
      }
      if (props.value.length === 0) {
        return;
      }
      const columnCount = props.value[0].length;
      for (const row of props.value) {
        if (!Array.isArray(row) || row.length !== columnCount) {
          return error;
        }
        for (const cell of row) {
          if (typeof cell !== "boolean") {
            return error;
          }
        }
      }
    },
    selectedCells: PropTypes.array,
    maxRows: PropTypes.number,
    maxColumns: PropTypes.number,
    onSelectionStart: PropTypes.func,
    onInput: PropTypes.func,
    onChange: PropTypes.func,
    children: (props) => {
      if (TableDragSelect.propTypes.value(props)) {
        return; // Let error be handled elsewhere
      }
      const error = new Error(
        "Invalid prop `children` supplied to `TableDragSelect`. Validation failed."
      );
      const trs = React.Children.toArray(props.children);
      const rowCount = props.value.length;
      const columnCount = props.value.length === 0 ? 0 : props.value[0].length;
      if (trs.length !== rowCount) {
        return error;
      }
      for (const tr of trs) {
        const tds = React.Children.toArray(tr.props.children);
        if (tr.type !== "tr" || tds.length !== columnCount) {
          return error;
        }
        for (const td of tds) {
          if (td.type !== "td") {
            return error;
          }
        }
      }
    },
  };

  static defaultProps = {
    selectedCells: {},
    value: [],
    maxRows: Infinity,
    maxColumns: Infinity,
    onSelectionStart: () => {},
    onInput: () => {},
    onChange: () => {},
  };

  state = {
    selectedCells: {},
    selectionStarted: false,
    startRow: null,
    startColumn: null,
    endRow: null,
    endColumn: null,
    addMode: null,
  };

  componentDidMount = () => {
    window.addEventListener("mouseup", this.handleTouchEndWindow);
    window.addEventListener("touchend", this.handleTouchEndWindow);
  };

  componentWillUnmount = () => {
    window.removeEventListener("mouseup", this.handleTouchEndWindow);
    window.removeEventListener("touchend", this.handleTouchEndWindow);
  };

  render = () => {
    return (
      <table className="table-drag-select">
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  };

  renderRows = () => {
    return React.Children.map(this.props.children, (tr, i) => {
      return (
        <tr key={i} {...tr.props}>
          {React.Children.map(tr.props.children, (cell, j) => {
            if (!this.props.value[i][j]) return;
            const { isStartingMonth, isOutOfRange } = this.props.value[i][j];
            const isSelected = this.props.selectedCells[i]?.[j];
            return (
              <Cell
                key={`${i}:${j}`}
                onTouchStart={this.handleTouchStartCell}
                onTouchMove={this.handleTouchMoveCell}
                selected={isSelected}
                beingSelected={this.isCellBeingSelected(i, j, isOutOfRange)}
                // isBeingStartSelected={this.isBeingStartSelecting(i, j)}
                // isBeingEndSelected={this.isBeingEndSelecting(i, j)}
                isDeselecting={
                  this.state.addMode ? !this.state.addMode.isSelected : false
                }
                isStartingMonth={isStartingMonth}
                {...cell.props}
              >
                {cell.props.children}
              </Cell>
            );
          })}
        </tr>
      );
    });
  };

  handleTouchStartCell = (e) => {
    const isLeftClick = e.button === 0;
    const isTouch = e.type !== "mousedown";
    if (!this.state.selectionStarted && (isLeftClick || isTouch)) {
      e.preventDefault();
      const eventLocation = eventToCellLocation(e);
      if (!eventLocation) return;
      const { row, column } = eventLocation;
      this.props.onSelectionStart({ row, column });
      this.setState({
        shadowValue: [{ row, column }],
        selectionStarted: true,
        startRow: row,
        startColumn: column,
        endRow: row,
        endColumn: column,
        addMode: this.props.value[row]
          ? {
              isSelected: !this.props.selectedCells[row]?.[column],
            }
          : { isSelected: false },
      });
    }
  };

  handleTouchMoveCell = (e) => {
    if (this.state.selectionStarted) {
      e.preventDefault();
      const eventLocation = eventToCellLocation(e);
      if (!eventLocation) return;
      const { row, column } = eventLocation;
      const { startRow, startColumn, endRow, endColumn } = this.state;

      if (endRow !== row || endColumn !== column) {
        const nextRowCount =
          startRow === null && endRow === null
            ? 0
            : Math.abs(row - startRow) + 1;
        const nextColumnCount =
          startColumn === null && endColumn === null
            ? 0
            : Math.abs(column - startColumn) + 1;

        if (nextRowCount <= this.props.maxRows) {
          this.setState({ endRow: row });
        }

        if (nextColumnCount <= this.props.maxColumns) {
          this.setState({ endColumn: column });
        }
      }
    }
  };

  handleTouchEndWindow = (e) => {
    const isLeftClick = e.button === 0;
    const isTouch = e.type !== "mousedown";
    const mode = this.state.addMode;
    if (this.state.selectionStarted && (isLeftClick || isTouch)) {
      const isSelecting = mode.isSelected;
      const minRow = Math.min(this.state.startRow, this.state.endRow);
      const maxRow = Math.max(this.state.startRow, this.state.endRow);
      const minColumn = Math.min(this.state.startColumn, this.state.endColumn);
      const maxColumn = Math.max(this.state.startColumn, this.state.endColumn);

      this.setState({ selectionStarted: false });
      const cells = this.props.value;
      const selectedCellsCopy = { ...this.props.selectedCells };

      for (let row = minRow; row <= maxRow; row++) {
        for (let column = minColumn; column <= maxColumn; column++) {
          if (cells[row][column].disabled || cells[row][column].isOutOfRange)
            continue;
          const currentRow = selectedCellsCopy[row]
            ? {
                ...selectedCellsCopy[row],
              }
            : {};
          const currentCell = currentRow[column];

          const props = cells[row][column];

          if (isSelecting) {
            if (currentRow) {
              selectedCellsCopy[row] = {
                ...currentRow,
                [column]: props,
              };
            } else {
              selectedCellsCopy[row] = {
                [column]: props,
              };
            }
          } else if (currentCell) {
            selectedCellsCopy[row] = {
              ...currentRow,
              [column]: false,
            };
          }
        }
      }

      this.props.onChange({
        selectedCells: selectedCellsCopy,
      });
    }
  };

  sliceCells = ({ minRow, maxRow, state }) => {
    const products = this.props.products;
    const value = clone(state);
    const changedLines = {};
    const rowLength = state && state[0] ? state[0].length : 0;
    for (let row = minRow; row <= maxRow; row++) {
      const maxColumn = state[row].length;
      let selectedCells = [];
      let selectedCellsCount = null;
      for (let column = 0; column < maxColumn; column++) {
        const currColumn = state[row][column];
        if (currColumn.isSelected) {
          selectedCellsCount = selectedCellsCount
            ? { ...selectedCellsCount, length: selectedCellsCount.length + 1 }
            : { start: column, length: 1 };
        } else {
          if (!!selectedCellsCount) selectedCells.push(selectedCellsCount);
          selectedCellsCount = null;
        }

        if (column === maxColumn - 1) {
          if (!!selectedCellsCount) selectedCells.push(selectedCellsCount);
          selectedCellsCount = null;
        }
      }
      changedLines[row] = selectedCells;
    }

    for (const key in changedLines) {
      const events = changedLines[key];
      const addToCartEvents = [];

      for (let i = 0; i < events.length; i++) {
        const { start, length } = events[i];
        const end = start + length;
        for (let j = start; j < end; j++) {
          const obj = { isSelected: true };
          const addToCartObj = {
            year: this.props.displayed.year,
            month: this.props.displayed.month + 1,
          };
          if (value[key][j].isStartingMonth) obj.isStartingMonth = true;
          if (j === start) {
            addToCartObj.startDay = j + 1;
            addToCartObj.endDay = end;
            addToCartObj.product = products[key];
            obj.length = end - start;
          }
          if (j === end - 1) {
            if (end >= rowLength) obj.isOverflowing = true;
          }
          if (addToCartObj.startDay) addToCartEvents.push(addToCartObj);
          value[key][j] = obj;
        }
      }
      this.props.setAddToCartEvents({
        key: this.props.timestamp.from,
        row: key,
        value: addToCartEvents,
      });
    }
    return value;
  };

  isCellBeingSelected = (row, column, disabled) => {
    const minRow = Math.min(this.state.startRow, this.state.endRow);
    const maxRow = Math.max(this.state.startRow, this.state.endRow);
    const minColumn = Math.min(this.state.startColumn, this.state.endColumn);
    const maxColumn = Math.max(this.state.startColumn, this.state.endColumn);

    return (
      this.state.selectionStarted &&
      !disabled &&
      row >= minRow &&
      row <= maxRow &&
      column >= minColumn &&
      column <= maxColumn
    );
  };

  isBeingStartSelecting = (row, column) => {
    if (!this.state.selectionStarted) return false;
    if (this.state.startColumn > this.state.endColumn)
      return this.state.endColumn === column;
    return column === this.state.startColumn;
  };

  isBeingEndSelecting = (row, column) => {
    if (!this.state.selectionStarted) return false;
    if (this.state.startColumn > this.state.endColumn)
      return this.state.startColumn === column;
    return column === this.state.endColumn;
  };
}

class Cell extends React.Component {
  // This optimization gave a 10% performance boost while drag-selecting
  // cells
  shouldComponentUpdate = (nextProps) =>
    this.props.beingSelected !== nextProps.beingSelected ||
    this.props.selected !== nextProps.selected ||
    this.props.isBeingStartSelected !== nextProps.isBeingStartSelected ||
    this.props.isBeingEndSelected !== nextProps.isBeingEndSelected ||
    this.props.isStartingMonth !== nextProps.isStartingMonth ||
    this.props.isDeselecting !== nextProps.isDeselecting;

  componentDidMount = () => {
    // We need to call addEventListener ourselves so that we can pass
    // {passive: false}
    this.td.addEventListener("touchstart", this.handleTouchStart, {
      passive: false,
    });
    this.td.addEventListener("touchmove", this.handleTouchMove, {
      passive: false,
    });
  };

  componentWillUnmount = () => {
    this.td.removeEventListener("touchstart", this.handleTouchStart);
    this.td.removeEventListener("touchmove", this.handleTouchMove);
  };

  render = () => {
    let {
      className = "",
      disabled,
      beingSelected,
      selected,
      isDeselecting,
      isStartingMonth,
    } = this.props;
    if (isStartingMonth) {
      className += " cell-start-month-selected";
    }
    if (disabled) {
      className += " cell-disabled";
    } else {
      className += " cell-enabled";
      if (selected) {
        className += " cell-selected";
      }
      if (beingSelected) {
        className += " cell-being-selected";
        if (isDeselecting) {
          className += " cell-being-deselected";
        }
      }
    }

    return (
      <td
        ref={(td) => (this.td = td)}
        className={className}
        onMouseDown={this.handleTouchStart}
        onMouseMove={this.handleTouchMove}
        // {...props}
      >
        {isStartingMonth && <div className="empty-selected-month-block"></div>}
        {selected && <div className="empty-selected-block"></div>}
        {beingSelected && <div className="empty-block"></div>}
        {this.props.children}
      </td>
    );
  };

  handleTouchStart = (e) => {
    if (!this.props.disabled) {
      this.props.onTouchStart(e);
    }
  };

  handleTouchMove = (e) => {
    this.props.onTouchMove(e);
  };
}

// Takes a mouse or touch event and returns the corresponding row and cell.
// Example:
//
// eventToCellLocation(event);
// {row: 2, column: 3}
const eventToCellLocation = (e) => {
  let target;
  // For touchmove and touchend events, e.target and e.touches[n].target are
  // wrong, so we have to rely on elementFromPoint(). For mouse clicks, we have
  // to use e.target.
  if (e.touches) {
    const touch = e.touches[0];
    target = document.elementFromPoint(touch.clientX, touch.clientY);
  } else {
    target = e.target;
    while (target.tagName !== "TD") {
      target = target.parentNode;
    }
  }
  if (!target) return;
  return {
    row: target.parentNode.rowIndex,
    column: target.cellIndex,
  };
};

const mapStateToProps = ({ date: { displayed, range } }: RootState) => {
  return {
    displayed,
    timestamp: range.timestamp,
  };
};

export default connect(mapStateToProps, { setAddToCartEvents })(
  TableDragSelect
) as any;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableDragSelect from "src/lib/Selectable";
import Loader from "src/components/Loader/Loader";
import { Product } from "src/store/slices/products";
import { setSelectedEvents } from "src/store/slices/date";
import { RootState } from "src/store/store";
import { getOrderUrl, parseEvents } from "src/utils";
import "./BodyProductList.styles.scss";

export interface IBodyProductListProps {
  products: Product[];
  isLoading: boolean;
}

let filterTimeout: any;

const BodyProductList: React.FC<IBodyProductListProps> = ({
  products,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const rangeDays = useSelector(({ date }: RootState) => date.range.days);
  const rangeTimestamp = useSelector(
    ({ date }: RootState) => date.range.timestamp
  );
  const selectedEvents = useSelector(
    ({ date }: RootState) => date.selectedEvents
  );
  const overflowedEvents = useSelector(
    ({ date }: RootState) => date.overflowEvents
  );
  const currentDate = useSelector(({ date }: RootState) => date.current);
  const displayedDate = useSelector(({ date }: RootState) => date.displayed);

  const parsedProducts = useMemo(
    () =>
      parseEvents({
        groups: products,
        rangeTimestamp,
        daysInMonthRange: rangeDays,
        currentDate,
        displayedDate,
      }),
    [products]
  );

  const [currentCells, setCurrentCells] = useState(parsedProducts.rows);
  const [highlightedCell, setHighlightedCell] = useState<string>("");
  const [onLeave, setOnLeave] = useState(false);

  useEffect(() => {
    const currentOverflowedEvents = overflowedEvents[rangeTimestamp.from];
    if (!parsedProducts.rows.length) return;
    const cells = JSON.parse(JSON.stringify(parsedProducts.rows));
    if (currentOverflowedEvents) {
      for (const key in currentOverflowedEvents) {
        const { isOverflowing } = currentOverflowedEvents[key];
        cells[key][0].isStartingMonth = isOverflowing;
      }
    }
    setCurrentCells(cells);
  }, [parsedProducts.rows]);

  function handleChange({ selectedCells }: any) {
    const key = rangeTimestamp.from;
    const nextKey = rangeTimestamp.to;
    dispatch(
      setSelectedEvents({
        key,
        nextKey,
        events: selectedCells,
        products: parsedProducts.labels,
      })
    );
    document?.body?.classList.remove("selecting-mode");
  }

  function handleSelectionStart() {
    document?.body?.classList.add("selecting-mode");
  }

  const highlightCell = (id: string) => {
    setHighlightedCell(id);
    setOnLeave(false);
  };

  function deHightlight() {
    setOnLeave(true);
    clearTimeout(filterTimeout);

    filterTimeout = setTimeout(() => {
      setOnLeave((prevState) => {
        if (prevState) setHighlightedCell("");
        return prevState;
      });
    }, 25);
  }

  const getProps = (postId: string, owner?: boolean) => {
    return {
      ...(owner && {
        href: getOrderUrl(postId),
        onMouseEnter: () => highlightCell(postId),
        onMouseOut: deHightlight,
      }),
    };
  };

  return (
    <div className="body-products">
      <Loader isLoading={isLoading} />
      <div className="body-products__labelList">
        {parsedProducts.labels.map(({ id, name, slug }) => (
          <a
            key={id}
            href={slug}
            className="body-products__itemContent"
            title={name}
          >
            <span>{name}</span>
          </a>
        ))}
      </div>
      {!!currentCells.length && (
        <TableDragSelect
          value={currentCells}
          selectedCells={selectedEvents[rangeTimestamp.from] ?? {}}
          products={parsedProducts.labels}
          onSelectionStart={handleSelectionStart}
          onChange={handleChange}
        >
          {parsedProducts.rows.map((cells, rowIndex) => {
            return (
              <tr key={rowIndex} className="body-products__item">
                {cells.map(
                  (
                    event: {
                      owner?: boolean;
                      isSelected: boolean;
                      isOutOfRange: boolean;
                      disabled?: boolean;
                      isStarting?: {
                        postId: string;
                        isAdmin?: boolean;
                        onHold?: boolean;
                      };
                      isEnding?: {
                        postId: string;
                        isAdmin?: boolean;
                        onHold?: boolean;
                      };
                      isWholeDay?: {
                        postId: string;
                        isAdmin?: boolean;
                        onHold?: boolean;
                      };
                    },
                    colIndex: number
                  ) => {
                    const isOwner = event.owner;
                    const Tag = isOwner ? "a" : "div";

                    const overflowedEvent =
                      overflowedEvents[rangeTimestamp.from]?.[rowIndex];

                    return (
                      <td
                        key={`${JSON.stringify(event)}:${
                          highlightedCell === event?.isStarting?.postId
                        }:${highlightedCell === event?.isWholeDay?.postId}:${
                          highlightedCell === event?.isEnding?.postId
                        }:${overflowedEvents[rangeTimestamp.from]?.[rowIndex]}`}
                        className={`body-products__cell ${
                          colIndex === 0 && overflowedEvent
                            ? "cell-start-month-selected"
                            : ""
                        }`}
                        //@ts-ignore
                        disabled={event.disabled}
                      >
                        {event.isOutOfRange && (
                          <div className="body-products__out-of-range"></div>
                        )}
                        {colIndex === 0 && overflowedEvent && (
                          <div className="empty-selected-month-block"></div>
                        )}
                        {event.isStarting && (
                          <Tag
                            className={`body-products__event ${
                              event.isStarting.isAdmin ? "isAdmin" : ""
                            } ${
                              event.isStarting.onHold
                                ? "body-products__event--on-hold"
                                : ""
                            } ${
                              highlightedCell === event.isStarting.postId
                                ? "highlight"
                                : ""
                            }`}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              marginLeft: "50%",
                              width: "50%",
                            }}
                            {...getProps(event.isStarting.postId, isOwner)}
                          ></Tag>
                        )}
                        {event.isWholeDay && (
                          <Tag
                            className={`body-products__event ${
                              event.isWholeDay.isAdmin ? "isAdmin" : ""
                            } ${
                              event.isWholeDay.onHold
                                ? "body-products__event--on-hold"
                                : ""
                            } ${
                              highlightedCell === event.isWholeDay.postId
                                ? "highlight"
                                : ""
                            }`}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                            }}
                            {...getProps(event.isWholeDay.postId, isOwner)}
                          ></Tag>
                        )}
                        {event.isEnding && (
                          <Tag
                            className={`body-products__event ${
                              event.isEnding.isAdmin ? "isAdmin" : ""
                            } ${
                              event.isEnding.onHold
                                ? "body-products__event--on-hold"
                                : ""
                            } ${
                              highlightedCell === event.isEnding.postId
                                ? "highlight"
                                : ""
                            }`}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "50%",
                              borderRight: "1px solid #e0e0e0",
                            }}
                            {...getProps(event.isEnding.postId, isOwner)}
                          ></Tag>
                        )}
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        </TableDragSelect>
      )}
    </div>
  );
};

export default BodyProductList;

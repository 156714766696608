import React from "react";
import "./Loader.styles.scss";

export interface ILoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className="loader">
      <img
        src="https://rezerwacja.lowiskozgoda.pl/wp-content/plugins/bookings-and-appointments-for-woocommerce/includes/booking-callender/icons/loading.gif"
        alt="loader"
      />
    </div>
  ) : null;
};

export default Loader;

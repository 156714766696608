import React from "react";
import "./Legend.styles.scss";

const Legend: React.FC = (props) => {
  return (
    <div className="legend">
      <div className="legend__wrapper">
        <div className="legend__item">
          <div className="legend__symbol legend__symbol--reserved"></div>
          <div className="legend__name">Zarezerwowane</div>
        </div>
        <div className="legend__item">
          <div className="legend__symbol legend__symbol--on-hold"></div>
          <div className="legend__name">Oczekujące na płatność</div>
        </div>
      </div>
    </div>
  );
};

export default Legend;

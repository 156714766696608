import { getDay, getUnixTime, isBefore } from "date-fns";
import { IInitilaDate } from "src/store/slices/date";
import { Group } from "src/store/slices/products";

export const GLOBAL_URL = "https://rezerwacja.lowiskozgoda.pl";

export const months = [
  "Sty",
  "Lut",
  "Mar",
  "Kwi",
  "Maj",
  "Cze",
  "Lip",
  "Sie",
  "Wrz",
  "Paź",
  "Lis",
  "Gru",
];

export const wholeMonths = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export const daysOfWeek = ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"];

interface IGetDays {
  range: { from: number; to: number };
  date: { year: number; month: number };
}

export const getOrderUrl = (id: string) => {
  return `${GLOBAL_URL}/wp-admin/post.php?post=${id}&action=edit`;
};

export const isOnHold = (status: string) =>
  ["wc-pending", "wc-on-hold"].includes(status);

export const getDays = ({ range, date }: IGetDays) => {
  const output = [];
  const daysLength = range.to;
  const { year, month } = date;
  for (let i = 1; i <= daysLength; i++) {
    output.push({
      number: i,
      label: daysOfWeek[getDay(new Date(year, month, i))],
      active: false,
    });
  }
  return output;
};

export const getCells = (
  range: { from: number; to: number },
  currentDate: IInitilaDate,
  displayedDate: IInitilaDate
) => {
  const output = [];
  const cellsLength = range.to;
  for (let i = 1; i <= cellsLength; i++) {
    const { year, month, day } = currentDate;
    const isOutOfRange = isBefore(
      new Date(displayedDate.year, displayedDate.month, i),
      new Date(year, month, day)
    );
    output.push({ isSelected: false, isOutOfRange });
  }
  return output;
};

export const parseEvents = ({
  groups,
  rangeTimestamp,
  daysInMonthRange,
  currentDate,
  displayedDate,
}: {
  groups: Group[];
  rangeTimestamp: { from: number; to: number };
  daysInMonthRange: { from: number; to: number };
  currentDate: IInitilaDate;
  displayedDate: IInitilaDate;
}) => {
  const labels = [];
  const rows = [];

  const daysInMonth = getCells(
    daysInMonthRange,
    currentDate,
    displayedDate
  ) as any[];

  for (let i = 0; i < groups.length; i++) {
    const currentGroup = groups[i];
    const events = currentGroup.events;
    const cells = JSON.parse(JSON.stringify(daysInMonth));

    for (let j = 0; j < events.length; j++) {
      const currentEvent = events[j];
      const eventStartDate = new Date(currentEvent.from);
      const eventStartDay = eventStartDate.getDate();
      const eventStartTimestamp = getUnixTime(eventStartDate);
      const eventEndDate = new Date(currentEvent.to);
      eventEndDate.setDate(eventEndDate.getDate() + 1);
      const eventEndDay = eventEndDate.getDate();
      const eventEndTimestamp = getUnixTime(new Date(eventEndDate));

      // const eventBase = {
      //   id: currentEvent.id,
      //   postId: currentEvent.post_id,
      //   isAdmin: currentEvent.isAdmin,
      //   owner: currentEvent.owner,
      //   disabled: true,
      // };
      if (
        (eventStartTimestamp < rangeTimestamp.from &&
          eventEndTimestamp < rangeTimestamp.from) ||
        (eventStartTimestamp > rangeTimestamp.to &&
          eventEndTimestamp > rangeTimestamp.to)
      )
        continue;

      const start =
        eventStartTimestamp < rangeTimestamp.from
          ? { index: 0 }
          : { index: eventStartDay - 1, isStarting: true };

      const end =
        eventEndTimestamp > rangeTimestamp.to
          ? { index: cells.length - 1 }
          : { index: eventEndDay - 1, isEnding: true };

      const eventData = {
        onHold: isOnHold(currentEvent.status),
        postId: currentEvent.post_id,
        isAdmin: currentEvent.isAdmin,
      };

      for (let i = start.index; i <= end.index; i++) {
        cells[i].owner = currentEvent.owner;
        if (i === start.index && start.isStarting) {
          cells[i].isStarting = eventData;
          cells[i].disabled = true;
        } else if (i === end.index && end.isEnding) {
          cells[i].isEnding = eventData;
        } else {
          cells[i].isWholeDay = eventData;
          cells[i].disabled = true;
        }
      }
    }
    rows.push(cells);
    labels.push({
      id: currentGroup.id,
      name: currentGroup.name,
      slug: currentGroup.slug,
    });
  }
  return { labels, rows };
};

import { createSlice } from "@reduxjs/toolkit";
import {
  format,
  getUnixTime,
  getMonth,
  getDate,
  getYear,
  getDaysInMonth,
  startOfMonth,
} from "date-fns";

const currentDate = new Date();

const initialDate = {
  year: getYear(currentDate),
  month: getMonth(currentDate),
  day: getDate(currentDate),
  timestamp: getUnixTime(currentDate),
  iso: format(currentDate, "yyyy-dd-MM"),
};

export type IInitilaDate = typeof initialDate;

export const dateSlice = createSlice({
  name: "date",
  initialState: {
    current: initialDate,
    displayed: initialDate,
    range: {
      timestamp: {
        from: getUnixTime(startOfMonth(currentDate)),
        to: getUnixTime(
          startOfMonth(
            new Date(initialDate.year, initialDate.month + 1, initialDate.day)
          )
        ),
      },
      days: {
        from: 1,
        to: getDaysInMonth(currentDate),
      },
    },
    overflowEvents: {},
    selectedEvents: {},
    selectedEventsList: {},
    addToCartEvents: {},
  },
  reducers: {
    incrementMonth: ({ displayed }) => {
      const { month } = displayed;
      if (month === 11) {
        displayed.month = 0;
        displayed.year++;
      } else {
        displayed.month++;
      }
    },
    decrementMonth: ({ displayed }) => {
      const { month } = displayed;
      if (month === 0) {
        displayed.month = 11;
        displayed.year--;
      } else {
        displayed.month--;
      }
    },
    incrementYear: ({ displayed }) => {
      displayed.year++;
    },
    decrementYear: ({ displayed }) => {
      displayed.year--;
    },
    setCurrentDate: (state) => {
      state.displayed = state.current;
    },
    setMonth: ({ displayed }, { payload }: { payload: number }) => {
      displayed.month = payload;
    },
    setRange: ({ range, displayed }) => {
      const { year, month } = displayed;
      const timestamp = {
        from: getUnixTime(startOfMonth(new Date(year, month))),
        to: getUnixTime(startOfMonth(new Date(year, month + 1))),
      };
      const days = {
        from: 1,
        to: getDaysInMonth(new Date(year, month)),
      };
      range.timestamp = timestamp;
      range.days = days;
    },
    setAddToCartEvents: (
      { addToCartEvents },
      { payload: { key, row, value } }
    ) => {
      if (!addToCartEvents[key]) {
        addToCartEvents[key] = {
          [row]: value,
        };
      } else {
        addToCartEvents[key] = {
          ...addToCartEvents[key],
          [row]: value,
        };
      }
    },
    setSelectedEvents: (
      { selectedEvents, overflowEvents, range, addToCartEvents, displayed },
      { payload: { key, nextKey, events, products } }
    ) => {
      const daysInMonth = range.days.to;
      selectedEvents[key] = events;
      for (const row in events) {
        let counter = 0;
        const rowEvents = [];
        for (const col in events[row]) {
          const colNum = +col;
          if (events[row][colNum]) {
            if (events[row][colNum + 1]) {
              counter += 1;
            } else {
              rowEvents.push({
                month: displayed.month + 1,
                year: displayed.year,
                startDay: colNum - counter + 1,
                endDay: colNum + 1,
                product: products[row],
              });
              counter = 0;
            }
          }
          if (colNum + 1 === daysInMonth) {
            overflowEvents[nextKey] = {
              ...overflowEvents[nextKey],
              [row]: events[row][colNum],
            };
          }
        }
        addToCartEvents[key] = {
          ...addToCartEvents[key],
          [row]: rowEvents,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  incrementMonth,
  decrementMonth,
  incrementYear,
  decrementYear,
  setCurrentDate,
  setMonth,
  setRange,
  setSelectedEvents,
  setAddToCartEvents,
} = dateSlice.actions;

export default dateSlice.reducer;
